import React, { useState, useEffect } from "react";
import {
  ArrowRight,
  ArrowDown,
  User,
  ChevronRight,
  ChevronLeft,
} from "lucide-react";
import { examples } from "./examples";
import { Shuffle } from "lucide-react";

// Constants
const TITLES = {
  SAFETY_FINETUNING: "Existing Refusal Training",
  TAMPER_RESISTANT: "Tamper-Resistant Training",
};

const COLUMNS = {
  BRITTLE_REFUSAL: {
    title: "Brittle Refusal Mechanism",
    image: "/images/brittle_refusal.svg",
    borderColor: "border-yellow-500",
    icon: "/images/brittle_refusal_icon.svg",
  },
  HARMFUL_FINETUNING: {
    title: "Harmful Fine-tuning",
    image: "/images/harmful_finetunning_0.svg",
    icon: "/images/harmful_finetuning_icon.svg",
  },
  SUCCESSFUL_RECOVERY: {
    title: "Successful Recovery",
    image: "/images/successful_recovery.svg",
    borderColor: "border-red-500",
    icon: "/images/successful_recovery_icon.svg",
  },
  TAMPER_RESISTANT: {
    title: "Tamper Resistant Defense",
    image: "/images/tamper_resistant.svg",
    borderColor: "border-yellow-500",
    icon: "/images/tamper_resistant_icon.svg",
  },
  UNSUCCESSFUL_RECOVERY: {
    title: "Unsuccessful Recovery",
    image: "/images/unsuccessful_recovery.svg",
    borderColor: "border-blue-300",
    icon: "/images/unsuccessful_recovery_icon.svg",
  },
};

const ExampleSafeguard = () => {
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const currentExample = examples[currentExampleIndex];

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, [window.innerWidth]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getNextExample = () => {
    setCurrentExampleIndex((prevIndex) => (prevIndex + 1) % examples.length);
  };

  const getPreviousExample = () => {
    setCurrentExampleIndex(
      (prevIndex) => (prevIndex - 1 + examples.length) % examples.length
    );
  };

  const renderColumn = (columnData, message, imageLeft = true) => (
    <div className="w-full md:w-[38%]">
      <div className="flex items-center justify-center space-x-2 mb-2">
        <img
          src={columnData.icon}
          alt={`${columnData.title} icon`}
          className="w-8 h-8"
        />
        <div className="text-sm font-semibold text-center">
          {columnData.title}
        </div>
      </div>
      <div className="flex items-center space-x-2">
        {imageLeft ? (
          <>
            <img
              src={columnData.image}
              alt={columnData.title}
              className="h-24 w-auto object-contain"
            />
            <div
              className={`flex-1 p-2 rounded-lg shadow-md bg-background border-solid border-2 ${columnData.borderColor}`}
            >
              <div className="text-xs">{message}</div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`flex-1 p-2 rounded-lg shadow-md bg-background border-solid border-2 ${columnData.borderColor}`}
            >
              <div className="text-xs">{message}</div>
            </div>
            <img
              src={columnData.image}
              alt={columnData.title}
              className="h-24 w-auto object-contain"
            />
          </>
        )}
      </div>
    </div>
  );

  const renderHarmfulFinetuning = (columnData) => (
    <div className="w-full md:w-[16%]">
      <div className="flex items-center justify-center space-x-2 mb-2">
        <img
          src={columnData.icon}
          alt={`${columnData.title} icon`}
          className="w-8 h-8"
        />
        <div className="text-sm font-semibold text-center">
          {columnData.title}
        </div>
      </div>
      <div className="flex justify-center">
        <img
          src={columnData.image}
          alt={columnData.title}
          className="h-24 w-auto object-contain"
        />
      </div>
    </div>
  );

  const chevronButtonStyle = "absolute p-1 rounded-full bg-white border border-black hover:bg-gray-100 transition-colors";

  return (
    <div className="mb-6">
      <div className="text-center mb-4">
        <div className="mt-2 flex justify-center items-center">
          <div className="w-1/2 mb-8 border-1 border-solid border-t border-gray-300"></div>
        </div>
        <h3 className="text-2xl font-semibold leading-8 inline-block">
          Example
        </h3>
      </div>

      <div className="flex mt-8 justify-center items-center mb-4 relative">
        <button
          onClick={getPreviousExample}
          className={`${chevronButtonStyle} left-0`}
          aria-label="Previous example"
        >
          <ChevronLeft className="w-3 h-3 sm:w-8 sm:h-8" />
        </button>
        <div className="flex items-center space-x-4 max-w-[80%] px-6 sm:px-0">
          <User className="w-6 h-6 sm:w-8 sm:h-8 text-gray-600 flex-shrink-0" />
          <div className="rounded-lg px-3 py-2 sm:px-4 sm:py-2 border-solid border-2 border-black shadow-md">
            <p className="whitespace-pre-line text-xs sm:text-sm text-black">
              {currentExample.userQuestion}
            </p>
          </div>
        </div>
        <button
          onClick={getNextExample}
          className={`${chevronButtonStyle} right-0`}
          aria-label="Next example"
        >
          <ChevronRight className="w-3 h-3 sm:w-8 sm:h-8" />
        </button>
      </div>

      <div className="mt-8">
        <h4
          className="text-xl font-semibold mb-4 pb-2"
          style={{ borderBottom: "2px solid #CCCCCC" }}
        >
          {TITLES.SAFETY_FINETUNING}
        </h4>
        <div className="flex flex-col md:flex-row items-stretch justify-between">
          {renderColumn(
            COLUMNS.BRITTLE_REFUSAL,
            currentExample.brittleRefusal,
            false
          )}
          <div className="flex md:hidden items-center justify-center h-8">
            <ArrowDown className="w-6 h-6 text-gray-400" />
          </div>
          <div className="hidden md:flex items-center justify-center w-[4%]">
            <ArrowRight className="w-6 h-6 mt-10 text-gray-400" />
          </div>
          {renderHarmfulFinetuning(COLUMNS.HARMFUL_FINETUNING)}
          <div className="flex md:hidden items-center justify-center h-8">
            <ArrowDown className="w-6 h-6 text-gray-400" />
          </div>
          <div className="hidden md:flex items-center justify-center w-[4%]">
            <ArrowRight className="w-6 h-6 mt-10 text-gray-400" />
          </div>
          {renderColumn(
            COLUMNS.SUCCESSFUL_RECOVERY,
            currentExample.successfulRecovery,
            !isMobile
          )}
        </div>
      </div>

      <div className="mt-8">
        <h4
          className="text-xl font-semibold mb-4 pb-2"
          style={{ borderBottom: "2px solid #BCE1F6" }}
        >
          {TITLES.TAMPER_RESISTANT}
        </h4>
        <div className="flex flex-col md:flex-row items-stretch justify-between">
          {renderColumn(
            COLUMNS.TAMPER_RESISTANT,
            currentExample.tamperResistant,
            false
          )}
          <div className="flex md:hidden items-center justify-center h-8">
            <ArrowDown className="w-6 h-6 text-gray-400" />
          </div>
          <div className="hidden md:flex items-center justify-center w-[4%]">
            <ArrowRight className="w-6 h-6 mt-10 text-gray-400" />
          </div>
          {renderHarmfulFinetuning(COLUMNS.HARMFUL_FINETUNING)}
          <div className="flex md:hidden items-center justify-center h-8">
            <ArrowDown className="w-6 h-6 text-gray-400" />
          </div>
          <div className="hidden md:flex items-center justify-center w-[4%]">
            <ArrowRight className="w-6 h-6 mt-10 text-gray-400" />
          </div>
          {renderColumn(
            COLUMNS.UNSUCCESSFUL_RECOVERY,
            currentExample.unsuccessfulRecovery,
            !isMobile
          )}
        </div>
      </div>
    </div>
  );
};

export default ExampleSafeguard;
