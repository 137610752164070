import React, { useState, useEffect } from "react";
import { Collapse, Button, Space } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { FileOutlined, GithubOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const HeaderPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, [window.innerWidth]);

  const authors = [
    { name: "Rishub Tamirisa*", affiliation: "1,2,9", bioLink: "" },
    { name: "Bhrugu Bharathi*", affiliation: "1,3", bioLink: "" },
    { name: "Long Phan", affiliation: "9", bioLink: "https://longphan.ai" },
    { name: "Andy Zhou", affiliation: "1,2", bioLink: "" },
    { name: "Alice Gatti", affiliation: "1", bioLink: "" },
    { name: "Tarun Suresh", affiliation: "1,2", bioLink: "" },
    { name: "Rowan Wang", affiliation: "6,8", bioLink: "" },
    { name: "Maxwell Lin", affiliation: "8", bioLink: "" },
    { name: "Justin Wang", affiliation: "5,8", bioLink: "" },
    { name: "Ron Arel", affiliation: "1,2", bioLink: "" },
    { name: "Andy Zou", affiliation: "5,8,9", bioLink: "" },
    { name: "Dawn Song", affiliation: "4", bioLink: "" },
    { name: "Bo Li", affiliation: "2,7", bioLink: "" },
    { name: "Dan Hendrycks", affiliation: "1,9", bioLink: "" },
    { name: "Mantas Mazeika", affiliation: "2,9", bioLink: "" },
  ];

  const affiliations = [
    { name: "Lapis Labs", link: "https://lapis.rocks/" },
    { name: "University of Illinois Urbana-Champaign", link: "" },
    { name: "University of California, San Diego", link: "" },
    { name: "University of California, Berkeley", link: "" },
    { name: "Carnegie Mellon University", link: "" },
    { name: "Harvard University", link: "" },
    { name: "University of Chicago", link: "" },
    { name: "Gray Swan AI", link: "https://grayswan.ai" },
    { name: "Center for AI Safety", link: "https://safe.ai" },
  ];
  let breakAuthorIndices = isMobile ? [1, 53] : [4, 8];

  const author_component = (
    <div>
      <p className="text-center text-[#3A6B65] text-sm sm:text-base md:text-lg font-semibold mb-4">
        {authors.map((author, index) => (
          <span key={index}>
            {author.bioLink ? (
              <a
                href={author.bioLink}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                {author.name}
              </a>
            ) : (
              <span>{author.name}</span>
            )}
            {author.contrib && <sup>{author.contrib}</sup>}
            <sup>{author.affiliation}</sup>
            {index !== authors.length - 1 && ", "}
            {breakAuthorIndices.includes(index) && <br />}
          </span>
        ))}
      </p>
      <p className="text-center text-xs sm:text-sm md:text-base mb-4 space-y-1">
        {affiliations.map((affiliation, index) => (
          <span key={index} className="block">
            <sup>{index + 1}</sup>
            {affiliation.link ? (<a
              href={affiliation.link}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              {affiliation.name}
            </a>): (
              <span> {affiliation.name}</span>
            )}
            {index !== affiliations.length - 1 && ", "}
          </span>
        ))}
      </p>
    </div>
  );

  const [activeKey, setActiveKey] = useState(["0"]);
  const onCollapseChange = (key) => {
    setActiveKey(key);
  };

  const githubLink = "https://github.com/rishub-tamirisa/tamper-resistance/";
  const paperLink = "https://arxiv.org/abs/2408.00761";
  const hfLink = "https://huggingface.co/collections/lapisrocks/tamper-resistant-safeguards-for-open-weight-llms-66b2dc4cc40442c79ec890a5"

  return (
    <div className="mb-[31px]">
      <h1 className="max-w-3xl mx-auto text-2xl sm:text-3xl md:text-4xl font-medium leading-tight text-center mt-20 mb-4.5">
        <span className="bg-[#BCE1F6] px-1.5">Tamper-Resistant Safeguards</span>
        <br /> for Open-Weight LLMs
      </h1>
      <Collapse
        ghost
        className="custom-collapse"
        bordered={false}
        onChange={onCollapseChange}
      >
        <Panel
          header={
            <Button ghost className="customDashedButton">
              <div className="text-center text-black text-lg font-medium leading-6 mb-3.5">
                <span>Authors</span>
                <span className="ml-1.5">
                  {activeKey.includes("1") ? (
                    <CaretUpOutlined className="text-black" />
                  ) : (
                    <CaretDownOutlined className="text-black" />
                  )}
                </span>
              </div>
            </Button>
          }
          key="1"
          showArrow={false}
        >
          <div>{author_component}</div>
        </Panel>
      </Collapse>

      <div className={`flex ${isMobile ? 'flex-col' : 'flex-row flex-wrap'} 
      justify-center mt-5 ${isMobile ? 'space-y-3' : 'space-x-3'} items-center`}>
          <Button
            ghost
            className="customDashedButton"
            href={paperLink}
            target="_blank"
          >
            <span className="text-center text-black text-lg font-medium leading-6 mb-3.5">
              <FileOutlined className="mr-1" />
              Paper
            </span>
          </Button>
          <Button
            ghost
            className="customDashedButton"
            href={githubLink}
            target="_blank"
          >
            <span className="text-center text-black text-lg font-medium leading-6 mb-3.5">
              <GithubOutlined className="mr-1" />
              Github
            </span>
          </Button>
          <Button
            ghost
            className="customDashedButton"
            href={hfLink}
            target="_blank"
          >
            <span className="text-center text-black text-lg font-medium leading-6 mb-3.5">
            <img 
                src="/images/png/hf-logo.png" 
                alt="Hugging Face Logo" 
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  height: '24px',
                  width: 'auto',
                  marginRight: '0.2em',
                  marginBottom: '0.2em',
                }}
                onError={(e) => { 
                  e.target.onerror = null; 
                  e.target.src = "/images/png/default-logo.png";
                }}
              />              
              Collection
            </span>
          </Button>
      </div>
    </div>
  );
};

export default HeaderPage;
