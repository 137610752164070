import React, { useState, useEffect } from "react";
import { Divider } from "antd";

import "./BodyPage.scss";
import ExampleSafeguard from "./ExampleSafeguard";

const BodyPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dividerOrientation = isMobile ? "center" : "left";

  return (
    <div>
      <div>
        <div className="mb-10">
          {isMobile ? (
            <h2 className="text-xl font-semibold leading-9 mb-4.5 text-left mx-auto">
              <span
                className="pb-2 inline-block"
                style={{
                  borderBottom: `1px solid #93B3A5`,
                }}
              >
                What are Tamper-Resistant Safeguards?
              </span>
            </h2>
          ) : (
            <h2 className="text-3xl font-semibold leading-9 mb-4.5 text-left mx-auto">
              <Divider orientation="left" className="custom-divider">
                <div className="flex items-center">
                  <img
                    src="/images/tamper_resistant_icon.svg"
                    alt="Tamper Resistant Icon"
                    className="h-6 mr-2 w-auto object-contain"
                  />
                  <span>What are Tamper-Resistant Safeguards?</span>
                </div>
              </Divider>
            </h2>
          )}

          <p className="text-base mt-8 font-normal leading-[30px] text-left mx-auto">
            Tamper-Resistant Safeguards are security measures designed for
            open-weight large language models (LLMs) to protect against
            malicious modifications of the model's weights. Unlike traditional
            safeguards that focus on preventing input-based attacks, these
            advanced safeguards prevent adversaries with access to full model
            weights from recovering performance on harmful capabilities.
            <br />
            We develop a method, called <span className="font-bold">TAR</span>,
            for building tamper-resistant safeguards into open-weight LLMs such
            that adversaries cannot remove the safeguards even after thousands
            of steps of fine-tuning. In extensive evaluations and red teaming
            analyses, we find that our method greatly improves tamper-resistance
            while preserving benign capabilities. Our results demonstrate that
            tamper-resistance is a tractable problem, opening up a promising new
            avenue to improve the safety and security of open-weight LLMs.
          </p>
          <div className="mt-8 mb-8">
            <img
              src="images/tamper-resistant.svg"
              alt="Tamper-Resistant Safeguards Illustration"
              className="max-w-[95%] h-auto block"
            />
            <p className="text-base italic text-center mt-4 max-w-[80%] mx-auto">
              Figure 1: An illustration of existing brittle LLM safeguards
              compared to tamper-resistant training. Existing safeguards can be
              easily removed by adversaries with access to model weights,
              raising serious concerns about the safety of open-weight LLMs.
            </p>
          </div>

          <ExampleSafeguard />
          <br />
        </div>
        <div className="mb-10">
          <h2 className="text-3xl font-semibold leading-9 mb-4.5 text-left mx-auto">
            <Divider
              orientation={dividerOrientation}
              className="custom-divider"
            >
              Results
            </Divider>
          </h2>
          <p className="text-base font-normal leading-[30px] text-left mx-auto">
            Main results are shown below for training tamper resistant
            safeguards against three different hazardous domains: Bioweapons,
            Chemical Weapons, and Cyberweapons.
          </p>
          <div className="flex flex-col md:flex-row md:pt-0">
            <div className="w-full md:w-[60%] mb-8 md:mb-0 flex flex-col md:pr-4 md:pt-9">
              <img
                src="images/png/pareto_plot_combined-1.png"
                alt="Figure 1: Description of the first image"
                className="w-full h-full"
              />
              <p className="text-base italic text-center mt-4">
                Figure 1: Comparison of the general capabilities and Post-Attack
                Hazardous Knowledge error of 9 baseline safeguards compared to
                our new TAR method. Unlike baseline safeguards, our method
                provides far greater tamper-resistance at similar levels of
                general capability, measured via MMLU.{" "}
              </p>
            </div>
            <div className="w-full md:w-[58%] flex flex-col md:pl-4 md:pt-9">
              <img
                src="images/png/main.png"
                alt="Figure 2: Description of the second image"
                className="w-full h-auto"
              />
              <p className="text-base italic text-center mt-4">
                Table 2: Pre-Attack and average Post-Attack accuracies for WMDP
                Biology, Chemistry, and Cyber- security for TAR and all other
                baselines, reported for Llama-3-8B. The average Post-Attack
                accuracy is computed as the average accuracy across all 28
                fine-tuning attacks. TAR is the only method that maintains low
                Post-Attack recovery while preserving high Retain MMLU and low
                Forget accuracies.
              </p>
            </div>
          </div>

          {/* @misc{tamirisa2024tamperresistantsafeguardsopenweightllms,
      title={Tamper-Resistant Safeguards for Open-Weight LLMs}, 
      author={Rishub Tamirisa and Bhrugu Bharathi and Long Phan and Andy Zhou and Alice Gatti and Tarun Suresh and Maxwell Lin and Justin Wang and Rowan Wang and Ron Arel and Andy Zou and Dawn Song and Bo Li and Dan Hendrycks and Mantas Mazeika},
      year={2024},
      eprint={2408.00761},
      archivePrefix={arXiv},
      primaryClass={cs.LG},
      url={https://arxiv.org/abs/2408.00761}, 
} */}
        </div>
        <div className="mb-10">
          <h2 className="text-3xl font-semibold leading-9 mb-4.5 text-left mx-auto">
            <Divider orientation="left" className="custom-divider">
              Citation
            </Divider>
          </h2>
          <div className="citation text-xs">
            <pre>
              <div>
                <div>
                  {`@misc{tamirisa2024tamperresistantsafeguardsopenweightllms,`}
                  <br></br>
                </div>
                <div style={{ paddingLeft: "2em" }}>
                  {`title={Tamper\-Resistant Safeguards for Open-Weight LLMs},`}
                  <br></br>
                </div>
                <div style={{ paddingLeft: "2em" }}>
                  {`author={Rishub Tamirisa and Bhrugu Bharathi and Long Phan and Andy Zhou and Alice Gatti and Tarun Suresh and Maxwell Lin and Justin Wang and Rowan Wang and Ron Arel and Andy Zou and Dawn Song and Bo Li and Dan Hendrycks and Mantas Mazeika},`}
                  <br></br>
                </div>
                <div style={{ paddingLeft: "2em" }}>
                  {`year={2024},`}
                  <br></br>
                </div>
                <div style={{ paddingLeft: "2em" }}>
                  {`eprint={2408.00761},`}
                  <br></br>
                </div>
                <div style={{ paddingLeft: "2em" }}>
                  {`archivePrefix={arXiv},`}
                  <br></br>
                </div>
                <div
                  style={{ paddingLeft: "2em" }}
                >{`primaryClass={cs.LG},`}</div>
                <div
                  style={{ paddingLeft: "2em" }}
                >{`url={https://arxiv.org/abs/2408.00761}`}</div>
                <div>{`}`}</div>
              </div>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyPage;
